import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { IPostCard } from '../models/post.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { ITagPriority } from '../models/tag-priority.model';
import { getNodes } from '../utils/get-nodes';

interface IPostCardsContext {
    postCards: IPostCard[];
}

interface IPostsContextQueryResult {
    allPost: IQueryAllResult<IPostCard>;
}

const initialContext: IPostCardsContext = {
    postCards: [],
};

const PostCardsContext = createContext(initialContext);

export const PostCardsContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { allPost }: IPostsContextQueryResult = useStaticQuery(query);
    const postCardsMemo = useMemo(() => {
        return getNodes(allPost).sort((postA, postB) => {
            const postAHighestPriority = getHighestPriorityValue(postA);
            const postBHighestPriority = getHighestPriorityValue(postB);
            if (postBHighestPriority > postAHighestPriority) return 1;
            if (postBHighestPriority < postAHighestPriority) return -1;
            return 0;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PostCardsContext.Provider
            value={{
                postCards: postCardsMemo,
            }}
        >
            {children}
        </PostCardsContext.Provider>
    );
};

function getHighestPriorityValue(postCard: IPostCard): number {
    return Math.max(...postCard.tagsPriority.map((tagPriority) => tagPriority.priority));
}

export const usePostCardsContext = () => {
    const context = useContext(PostCardsContext);

    if (context === undefined) {
        throw new Error('usePostsContext was used outside of its Provider');
    }

    return context;
};

interface IUsePostCardsConfig {
    tagId?: ITagPriority['tagId'];
    excludeId?: IPostCard['articleId'];
}

export const usePostCards = ({ tagId, excludeId }: IUsePostCardsConfig): IPostCard[] => {
    const { postCards } = usePostCardsContext();
    return useMemo(() => {
        return getFilteredAndSortedPostCards({
            postCards,
            tagId,
            excludeId,
        });
    }, [tagId, excludeId, postCards]);
};

interface IGetFilteredAndSortedPostCardsConfig extends IUsePostCardsConfig {
    postCards: IPostCard[];
}

function getFilteredAndSortedPostCards({
    postCards,
    tagId,
    excludeId,
}: IGetFilteredAndSortedPostCardsConfig): IPostCard[] {
    let postCardsToReturn = [...postCards];

    if (excludeId) {
        postCardsToReturn = postCardsToReturn.filter(
            (postCard) => postCard.articleId !== excludeId
        );
    }

    if (tagId) {
        postCardsToReturn.sort((postA, postB) => {
            const postATagPriority = getTagPriorityFromPost(postA, tagId);
            const postBTagPriority = getTagPriorityFromPost(postB, tagId);

            const postATagPriorityValue = postATagPriority?.priority || 0;
            const postBTagPriorityValue = postBTagPriority?.priority || 0;

            const postATagPriorityIsMain = Number(postATagPriority?.isMain || false);
            const postBTagPriorityIsMain = Number(postBTagPriority?.isMain || false);

            if (postBTagPriorityIsMain > postATagPriorityIsMain) return 1;
            if (postBTagPriorityIsMain < postATagPriorityIsMain) return -1;

            if (postBTagPriorityValue > postATagPriorityValue) return 1;
            if (postBTagPriorityValue < postATagPriorityValue) return -1;

            return 0;
        });
    }

    return postCardsToReturn;
}

function getTagPriorityFromPost(
    post: IPostCard,
    tagId: ITagPriority['tagId']
): ITagPriority | undefined {
    return post.tagsPriority.find((tagPriority) => tagPriority.tagId === tagId);
}

export const useSinglePostCard = (articleId: IPostCard['articleId']) => {
    const { postCards } = usePostCardsContext();
    const [postCard, setPostCard] = useState<IPostCard | undefined>(
        getPostCardById(articleId, postCards)
    );

    useEffect(() => {
        setPostCard(getPostCardById(articleId, postCards));
    }, [articleId]);

    return postCard;
};

function getPostCardById(articleId: IPostCard['articleId'], postCards: IPostCard[]) {
    return postCards.find((postCard) => postCard.articleId === articleId);
}

const query = graphql`
    query {
        allPost(sort: { fields: publishedAt, order: DESC }, limit: 100) {
            edges {
                node {
                    ...postCardFields
                }
            }
        }
    }
`;
