module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Keino","short_name":"Keino","start_url":"/","background_color":"#5CFFF3","theme_color":"#5CFFF3","display":"standalone","icon":"/var/www/keinogrupa.pl/releases/26/frontend/src/assets/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"51d1dcf8b29779e1a02bf36506d2df7b"},
    },{
      plugin: require('../plugins/gatsby-plugin-ap-framer-motion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-ap-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","defaultLanguage":"pl","languages":["pl"],"siteUrl":"https://keinogrupa.pl","redirect":false,"pagination":{"translations":{"pl":"strona"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-M88FFXK","cookieName":"AP_ANALYTICS","dataLayerName":"dataLayer"},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
