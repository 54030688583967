import React, { createContext, useContext, useState } from 'react';

interface IIntroContext {
    isPlaying: boolean;
    isComplete: boolean;
    showIntro(): any;
    closeIntro(): any;
}

const initialContext: IIntroContext = {
    isPlaying: false,
    isComplete: false,
    showIntro: () => {},
    closeIntro: () => {},
};
const IntroContext = createContext(initialContext);

export const IntroContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isComplete, setIsComplete] = useState(false);

    const handleClose = () => {
        setIsPlaying(false);
        setIsComplete(true);
    };

    return (
        <IntroContext.Provider
            value={{
                isPlaying,
                isComplete,
                showIntro: () => setIsPlaying(true),
                closeIntro: () => handleClose(),
            }}
        >
            {children}
        </IntroContext.Provider>
    );
};

export const useIntroContext = () => {
    const context = useContext(IntroContext);

    if (context === undefined) {
        throw new Error('useModalContext was used outside of its Provider');
    }

    return context;
};
